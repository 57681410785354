.milkdown .ProseMirror {
  padding: 0px 0px;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
}

.milkdown {
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
}

.milkdown .editor a {
  color: blue;
  text-decoration: underline;
  &:hover {
    color: darkblue;
  }
}

.milkdown .editor p {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
  color: #475467;
}

.milkdown [contenteditable='true']:not([contenteditable='false']) p {
  color: #111827;
}

.milkdown milkdown-block-handle {
  display: none;
}

.milkdown p.crepe-placeholder::before {
  color: #9ca3af !important;
}

.milkdown milkdown-toolbar {
  position: absolute;
  left: 0 !important;
}
