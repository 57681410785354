.markdown {
  @apply prose mt-4 text-gray-700;
}

.markdown h1 {
  @apply my-4 text-3xl font-bold;
}

.markdown h2 {
  @apply my-3 text-2xl font-semibold;
}

/* Add more styles for other markdown elements */
